<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Danh sách quyền</h3>
          </template>

          <div>
            <el-table v-loading="loading" :data="list" header-row-class-name="thead-light">
              <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.index }}</span>
                </template>
              </el-table-column>

              <el-table-column width="150" align="left" label="Quyền">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>

              <el-table-column align="left" :label="'Mô tả'">
                <template slot-scope="scope">
                  <span>{{ scope.row.description }}</span>
                </template>
              </el-table-column>

              <el-table-column v-if="checkPermission(['manage_permission'])" align="center" label="" width="100">
                <template slot-scope="scope">
                  <base-button
                    v-if="scope.row.name !== 'admin'"
                    v-permission="['manage_permission']"
                    @click="handleEditPermissions(scope.row.id)"
                    class="view btn-link"
                    type="primary"
                    size="sm"
                    icon
                  >
                    <font-awesome-icon icon="user-edit" inverse />
                  </base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <el-dialog :visible.sync="dialogVisible" :title="'Chỉnh sửa quyền - ' + currentRole.name">
            <div v-loading="dialogLoading" class="form-container">
              <div class="permissions-container">
                <div class="block">
                  <el-form :model="currentRole" label-width="80px" label-position="top">
                    <el-form-item label="Truy cập menu">
                      <el-tree ref="menuPermissions" :data="menuPermissions" :default-checked-keys="permissionKeys(roleMenuPermissions)" :props="permissionProps" show-checkbox node-key="id" class="permission-tree" />
                    </el-form-item>
                  </el-form>
                </div>
                <div class="block">
                  <el-form :model="currentRole" label-width="80px" label-position="top">
                    <el-form-item label="Quyền">
                      <el-tree ref="otherPermissions" :data="otherPermissions" :default-checked-keys="permissionKeys(roleOtherPermissions)" :props="permissionProps" show-checkbox node-key="id" class="permission-tree" />
                    </el-form-item>
                  </el-form>
                </div>
                <div class="clear-left" />
              </div>
              <div style="text-align:right;">
                <base-button
                  @click="dialogVisible=false"
                  class="view"
                  type="danger"
                  size="md"
                  icon
                >
                  Huỷ
                </base-button>

                <base-button
                  @click="confirmPermission"
                  class="view"
                  type="primary"
                  size="md"
                  icon
                >
                  Xác nhận
                </base-button>
              </div>
            </div>
          </el-dialog>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Dialog, Button, Form, FormItem, Tree } from 'element-ui';
import permission from '@/directives/permission'; // Permission directive (v-permission)
import checkPermission from '@/utils/permission'; // Permission checking
import { labels } from '@/utils/labels';
import _ from 'lodash';

export default {
  name: 'Permissions',
  directives: { permission },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Tree.name]: Tree,
  },
  data() {
    return {
      currentRoleId: 1,
      list: [],
      loading: true,
      dialogLoading: false,
      dialogVisible: false,
      permissions: [],
      menuPermissions: [],
      otherPermissions: [],
      permissionProps: {
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      },
    };
  },
  computed: {
    currentRole() {
      const found = this.list.find(role => role.id === this.currentRoleId);
      if (found === undefined) {
        return { name: '', permissions: [] };
      }

      return found;
    },
    rolePermissions() {
      return this.classifyPermissions(this.currentRole.permissions).all;
    },
    roleMenuPermissions() {
      return this.classifyPermissions(this.currentRole.permissions).menu;
    },
    roleOtherPermissions() {
      return this.classifyPermissions(this.currentRole.permissions).other;
    },
  },
  created() {
    const key = 'roles.description.admin';
    console.log(_.at(labels, 'permission.' + key));
    this.getRoles();
    this.getPermissions();
  },

  methods: {
    checkPermission,
    async getRoles() {
      this.loading = true;
      const { data } = await this.$store.dispatch('permission/fetchRoles');
      this.list = data;
      this.list.forEach((role, index) => {
        role['index'] = index + 1;
        role['description'] = _.at(labels, 'permission.' + 'roles.description.' + role.name)[0];
      });
      this.loading = false;
    },

    async getPermissions() {
      const { data } = await this.$store.dispatch('permission/fetchPermissions');
      const { all, menu, other } = this.classifyPermissions(data);
      this.permissions = all;
      this.menuPermissions = menu;
      this.otherPermissions = other;
    },

    classifyPermissions(permissions) {
      const all = []; const menu = []; const other = [];
      permissions.forEach(permission => {
        const permissionName = permission.name;
        all.push(permission);
        if (permissionName.startsWith('view_menu')) {
          menu.push(this.normalizeMenuPermission(permission));
        } else {
          other.push(this.normalizePermission(permission));
        }
      });
      return { all, menu, other };
    },

    normalizeMenuPermission(permission) {
      return {
        id: permission.id,
        name: permission.name.substring(10).toLowerCase(),
        label: _.at(labels,'permission.permissions.menu.' + permission.name.substring(10).toLowerCase())[0],
      };
    },

    normalizePermission(permission) {
      return {
        id: permission.id,
        name: permission.name.toLowerCase(),
        label: _.at(labels, 'permission.permissions.can.' + permission.name.toLowerCase())[0],
        disabled: permission.name === 'manage_permission',
      };
    },

    permissionKeys(permissions) {
      return permissions.map(permssion => permssion.id);
    },

    handleEditPermissions(id) {
      this.dialogVisible = true;
      this.currentRoleId = id;
      this.$nextTick(() => {
        this.$refs.menuPermissions.setCheckedKeys(this.permissionKeys(this.roleMenuPermissions));
        this.$refs.otherPermissions.setCheckedKeys(this.permissionKeys(this.roleOtherPermissions));
      });
    },

    confirmPermission() {
      const checkedMenu = this.$refs.menuPermissions.getCheckedKeys();
      const checkedOther = this.$refs.otherPermissions.getCheckedKeys();
      const checkedPermissions = checkedMenu.concat(checkedOther);
      this.dialogLoading = true;

      this.$store.dispatch('permission/updateRole', {id: this.currentRole.id, permissions: checkedPermissions }).then(response => {
        this.$notify({
          message:
            !response.error ? 'Chỉnh sửa quyền thành công!' : 'Chỉnh sửa quyền không thành công!',
          timeout: 2000,
          icon: 'ni ni-bell-55',
          type: !response.error ? 'success' : 'danger',
          verticalAlign: 'bottom',
          horizontalAlign: 'right'
        });
        this.dialogLoading = false;
        this.dialogVisible = false;
        this.getRoles();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
